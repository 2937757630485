import { Box } from "@mui/material";

const CenteredBox = ({ children, style, className }) => (
  <Box
    className={className}
    xs={4}
    sm={4}
    md={12}
    sx={{
      justifyContent: "center",
      display: "flex",
      alignContent: "center",
      ...style,
    }}
  >
    {children}
  </Box>
);

export default CenteredBox;
