import { Fragment } from "react";
import MainMenu from "../NavBar/Menu";
import { useLocation } from "react-router-dom";

const Layout = (props) => {
  const location = useLocation();
  const currentURL = location.pathname;

  return (
    <Fragment>
      {currentURL !== "/autentificare" && <MainMenu />}
      <main>{props.children}</main>
      <footer></footer>
    </Fragment>
  );
};

export default Layout;
