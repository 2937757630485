import { useFieldArray } from "react-hook-form";
import Button from "../../Buttons/Button";
import GreenHouseForm from "./GreenHouseForm";

const GreenHousesForm = ({
  register,
  control,
  formErrors,
  getValues,
  idCustomer,
  setValue,
  onlyGreenHouses,
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "greenHouses",
  });

  return (
    <>
      <Button
        className="secondary-button me-4 mb-4"
        type="button"
        size="small"
        onClick={() => {
          append({
            name: `Sera ${fields.length + 1}`,
            modules: [
              {
                name: `Modul - Sera ${
                  fields.length + 1
                } - Motor; temp etc. - ${getValues(
                  "customer.firstName"
                )} ${getValues("customer.lastName")}`,
                serialNumber: "",
                blocked: false,
                exterior: false,
                newModule: true,
                echipamenteModules: [0, 1, 2, 3],
              },
            ],
            fromDB: false,
          });
        }}
        title={
          onlyGreenHouses ? "Adauga sera noua in baza de date" : "Adauga sera"
        }
      />
      {fields.map((item, index) => {
        return (
          <GreenHouseForm
            idCustomer={idCustomer}
            key={item.id}
            index={index}
            remove={remove}
            register={register}
            item={item}
            control={control}
            formErrors={formErrors}
            getValues={getValues}
            setValue={setValue}
          />
        );
      })}
    </>
  );
};
export default GreenHousesForm;
