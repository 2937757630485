import { Box } from "@mui/material";
import TextInput from "../../Input/TextInput";

const CustomerForm = ({ register, formErrors }) => (
  <Box>
    <Box className="row mb-4">
      <Box className="col">
        <TextInput
          register={register}
          fullWidth
          name="customer.firstName"
          required="Va rog introduceti un prenume!"
          label="Prenume"
          errors={formErrors}
        />
      </Box>
      <Box className="col">
        <TextInput
          register={register}
          fullWidth
          name="customer.lastName"
          required="Va rog introduceti numele de familie!"
          label="Nume familie"
          errors={formErrors}
        />
      </Box>
    </Box>

    <Box className="row mb-4">
      <Box className="col">
        <TextInput
          register={register}
          fullWidth
          name="customer.mail"
          required="Va rog introduceti utilizator!"
          label="Utilizator"
          errors={formErrors}
        />
      </Box>
      <Box className="col">
        <TextInput
          register={register}
          fullWidth
          name="customer.password"
          required="Va rog introduceti o parola!"
          label="Parola"
          type="password"
          errors={formErrors}
        />
      </Box>

      <Box className="col">
        <TextInput
          register={register}
          fullWidth
          name="customer.phone"
          label="Numar de telefon"
          errors={formErrors}
        />
      </Box>
    </Box>
  </Box>
);

export default CustomerForm;
