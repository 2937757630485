import React from "react";
import "./index.css";
import App from "./App";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import configureStore from "./redux";

const container = document.getElementById("root");
const root = createRoot(container);
const initialAuthState = JSON.parse(localStorage.getItem("user"));
let authStateInitial;

if (initialAuthState !== null) {
  //Auto log-out the user
  let dateNow = new Date().toISOString().split("T")[0];
  const expirationDate = new Date(initialAuthState.expirationDate)
    .toISOString()
    .split("T")[0];

  if (dateNow > expirationDate) {
    localStorage.removeItem("user");
    window.location.href = "/";
  }

  // Set initial state
  authStateInitial = {
    auth: {
      isAuthenticated: !!initialAuthState.token,
      expirationDate: initialAuthState.expirationDate,
      userId: initialAuthState.userId || null,
      errors: null,
    },
  };
}

let store = configureStore(authStateInitial);

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
