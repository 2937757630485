import { useState, useEffect, useCallback } from "react";
import { apis } from "../../services/api";
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Pagination,
} from "@mui/material";
import GradientText from "../../common/GradientText/GradientText";
import Button from "../../common/Buttons/Button";
import "../../sass/categories.scss";
import CustomerList from "./components/CustomerList";
import DropDown from "../../common/Select/DropDown";
import TextInput from "../../common/Input/TextInput";
import { Link, useHistory } from "react-router-dom";

const pageSize = [
  { id: 4, value: 4 },
  { id: 8, value: 8 },
  { id: 10, value: 10 },
  { id: 14, value: 14 },
  { id: 18, value: 18 },
  { id: 20, value: 20 },
];

const Customers = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    customers: [],
    currentIndex: -1,
    searchName: "",
    page: 1,
    count: 0,
    pageSize: 4,
  });
  const [error, setError] = useState();
  const navigate = useHistory();

  const getRequestParams = (searchName, page, pageSize) => {
    let params = {};

    if (searchName) {
      params["name"] = searchName;
    }

    if (page) {
      params["page"] = page - 1;
    }

    if (pageSize) {
      params["size"] = pageSize;
    }

    return params;
  };
  const user = JSON.parse(localStorage.getItem("user"));

  const getAllCustomers = useCallback((page, pageSize, searchName) => {
    setIsLoading(true);
    console.log("here1 sau 2 ");
    console.log(state.customers);
    const params = getRequestParams(searchName, page, pageSize);
    if (user !== null) {
      console.log("inside user not null");
      apis
        .getCustomers(params, user.userId)
        .then((response) => {
          const { customers, totalPages } = response;

          console.log("response");
          console.log(response);

          setState((prevState) => ({
            ...prevState,
            customers: customers,
            count: totalPages,
          }));
        })
        .catch((err) => {
          setError(err);
        })
        .finally(() => setIsLoading(false));
    }
  }, []);

  console.log("here1");
  console.log(state.customers);

  useEffect(() => {
    getAllCustomers(state.page, state.pageSize, state.searchName);
  }, [getAllCustomers, state.page, state.pageSize, state.searchName]);

  const changePageHandler = (evt, value) => {
    setState((prevState) => ({
      ...prevState,
      page: value,
    }));
  };

  const changePageSizeHandler = (evt) => {
    setState((prevState) => ({
      ...prevState,
      pageSize: evt.target.value,
    }));
  };
  const searchByNameHandler = (ev) => {
    setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        searchName: ev.target.value,
      }));
    }, 1000);
  };

  const emptyListMessage = state.customers.length === 0 && !isLoading && (
    <div>
      <span>Nu exista clienti!</span>
    </div>
  );

  return (
    <Box className="categories">
      <GradientText text="Clienti existenti" size="2.7em" />
      <Box className="row ">
        <Box className="col">
          <Link style={{ textDecoration: "none" }} to="/adaugare-client">
            <Button
              title="Adauga client nou"
              className="secondary-button mb-4"
            />
          </Link>
        </Box>
        <Box className="col-3">
          <DropDown
            name="page-size"
            data={pageSize}
            label="Numar clienti pe pagina"
            propToDisplay="value"
            multiple={false}
            defaultValue={4}
            className="me-4"
            fullWidth={true}
            onChange={changePageSizeHandler}
          />
        </Box>
        <Box className="col-7">
          <TextInput
            name="searchName"
            type="text"
            onChange={searchByNameHandler}
            withRegister={false}
            className=""
            fullWidth={true}
            label="Cautare dupa nume client"
          />
        </Box>
      </Box>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Nume</TableCell>
              <TableCell>Prenume</TableCell>
              <TableCell>Data adaugarii</TableCell>
              <TableCell>Actiuni</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <CustomerList
              navigate={navigate}
              customers={state.customers}
              loading={isLoading}
            />
          </TableBody>
        </Table>
        {emptyListMessage}
        {error && <span className="text-danger">{error}</span>}
      </TableContainer>
      <Box className="d-flex justify-content-center mt-4">
        <Pagination
          count={state.count}
          variant="outlined"
          color="primary"
          onChange={changePageHandler}
        />
      </Box>
    </Box>
  );
};

export default Customers;
