import { types } from "../types";
import { apis } from "../../services/api";

const logInReqStarted = () => {
  return {
    type: types.LOGIN_REQUESTED,
  };
};

const logInSuccess = (user) => {
  return {
    type: types.LOGIN_SUCCESS,
    payload: user,
  };
};

const logInFailed = (errors) => {
  return {
    type: types.LOGIN_FAILED,
    payload: errors,
  };
};

export const authActions = {
  login: (user) => {
    return (dispatch) => {
      dispatch(logInReqStarted());
      apis
        .login(user)
        .then((res) => {
          dispatch(logInSuccess(res));
        })
        .catch((err) => {
          dispatch(logInFailed(err));
        });
    };
  },
};
