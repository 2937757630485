import { Skeleton, TableCell, TableRow } from "@mui/material";
import { BiEdit, BiTrashAlt } from "react-icons/bi";
import "../../../sass/icons.scss";
import { formatDate } from "../../../helpers/date-helper";

const CustomerList = ({ customers, loading, navigate }) => (
  <>
    {!loading ? (
      customers?.map((customer) => (
        <TableRow
          key={customer.id}
          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
        >
          <TableCell component="th" scope="row">
            <strong className="category-name">{customer.firstName}</strong>
          </TableCell>
          <TableCell component="th" scope="row">
            <strong className="category-name">{customer.lastName}</strong>
          </TableCell>
          <TableCell>{formatDate(customer.createdAt)}</TableCell>
          <TableCell>
            <BiEdit
              className="me-3 icon icon-edit"
              size="20"
              onClick={() => {
                navigate.push(`/client/${customer.id}`);
              }}
            />
            <BiTrashAlt
              className="icon icon-delete"
              size="20"
              onClick={() => {
                // categoryAction(category, "delete");
              }}
            />
          </TableCell>
        </TableRow>
      ))
    ) : (
      <TableRow>
        <TableCell component="th" scope="row">
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell component="th" scope="row">
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell component="th" scope="row">
          <Skeleton animation="wave" />
        </TableCell>
        <TableCell component="th" scope="row">
          <Skeleton animation="wave" />
        </TableCell>
      </TableRow>
    )}
  </>
);
export default CustomerList;
