import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducers from "./reducers";

const componseEnhancers =
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const configureStore = (initialState) => {
  let store = createStore(
    rootReducers,
    initialState,
    componseEnhancers(applyMiddleware(thunk))
  );

  return store;
};

export default configureStore;
