import { Button } from "@mui/material";
import "../../sass/buttons.scss";

const GradientButton = ({
  submitting,
  type,
  variant,
  size,
  title,
  className,
  onClick,
}) => (
  <Button
    disabled={submitting ? true : false}
    className={className === null ? "gradient-button" : className}
    type={type}
    variant={variant}
    size={size}
    onClick={onClick}
  >
    <div>
      {submitting && (
        <div
          className="spinner-border spinner-border-sm text-light"
          role="status"
        />
      )}
      <>{title}</>
    </div>
  </Button>
);

export default GradientButton;
