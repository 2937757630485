import { types } from "../types";
import { initialState } from "./initialState";

export default function AuthReducers(
  state = initialState.authInitialState,
  action
) {
  const { payload, type } = action;

  switch (type) {
    case types.LOGIN_REQUESTED:
      return {
        ...state,
        loading: true,
        errors: null,
      };

    case types.LOGIN_SUCCESS:
      localStorage.setItem("user", JSON.stringify(payload));
      return {
        ...state,
        userId: payload.userId,
        isAuthenticated: !!payload.token,
        expirationDate: payload.expirationDate,
        loading: false,
        errors: null,
      };

    case types.LOGIN_FAILED:
      return {
        ...state,
        user: null,
        isAuthenticated: false,
        loading: false,
        errors: payload,
      };

    case types.LOGOUT:
      localStorage.removeItem("user");
      return {
        ...state,
        user: null,
        isAuthenticated: !!payload.token,
        loading: false,
        errors: null,
      };

    default:
      return state;
  }
}
