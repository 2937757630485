import { useState, useCallback, useEffect } from "react";
import { Grid, Container, Box } from "@mui/material";
import { Category, Book, RemoveRedEyeRounded } from "@mui/icons-material";
import { Button } from "@mui/material";
import CategoryForm from "../../common/Category/CategoryForm";
import { Link } from "react-router-dom";
import { apis } from "../../services/api";

const Dashboard = () => {
  const [openCategoryModal, setOpenCategoryModal] = useState(false);
  const [countGreenHouses, setCountGreenHouses] = useState(0);
  const [countModules, setCountModules] = useState(0);
  const [isGreenCountLoading, setIsGreenCountLoading] = useState(false);
  const [isModulesCountLoading, setIsModulesCountLoading] = useState(false);
  const [countCustomers, setCountCustomers] = useState(0);
  const [isCustomersCountLoading, setIsCustomersCountLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));

  const getCountGreenHouse = useCallback(() => {
    setIsGreenCountLoading(true);
    if (user !== null) {
      apis
        .countGreenHouse(user.userId)
        .then((response) => {
          setCountGreenHouses(response);
        })
        .catch((err) => {
          //setError(err);
        })
        .finally(() => setIsGreenCountLoading(false));
    }
  }, []);

  const getCountModules = useCallback(() => {
    setIsModulesCountLoading(true);
    if (user !== null) {
      apis
        .countModules(user.userId)
        .then((response) => {
          setCountModules(response);
        })
        .catch((err) => {
          //setError(err);
        })
        .finally(() => setIsModulesCountLoading(false));
    }
  }, []);

  const getCountCustomers = useCallback(() => {
    setIsCustomersCountLoading(true);
    if (user !== null) {
      apis
        .countCustomers(user.userId)
        .then((response) => {
          setCountCustomers(response);
        })
        .catch((err) => {
          //setError(err);
        })
        .finally(() => setIsCustomersCountLoading(false));
    }
  }, []);

  useEffect(() => {
    getCountGreenHouse();
    getCountCustomers();
    getCountModules();
  }, [getCountGreenHouse, getCountCustomers]);
  return (
    <Container>
      <Grid container spacing={7}>
        <Grid
          item
          xs={4}
          sx={{
            color: "#fff",
          }}
        >
          <Box
            sx={{
              padding: 2,
              borderRadius: 5,
              boxShadow: 3,
              color: "#fff",
              background: "linear-gradient(to right bottom, #0D3C6B, #62A8EE)",
            }}
          >
            <div>
              <RemoveRedEyeRounded fontSize="large" />
            </div>

            <div className="mt-2">
              <span className="fs-6 fw-light">Numar total al serelor</span>
            </div>

            <div className="mt-2">
              {isGreenCountLoading ? (
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              ) : (
                <h2>{countGreenHouses}</h2>
              )}
            </div>
          </Box>
        </Grid>
        <Grid item xs={4} sx={{ color: "#fff" }}>
          <Box
            sx={{
              padding: 2,
              borderRadius: 5,
              boxShadow: 3,
              color: "#fff",
              background: "linear-gradient(to right bottom, #0D3C6B, #9bc7f3)",
            }}
          >
            <div>
              <Category fontSize="large" />
            </div>

            <div className="mt-2">
              <span className="fs-6 fw-light">Numar total de clienti</span>
            </div>

            <div className="mt-2">
              {isCustomersCountLoading ? (
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              ) : (
                <h2>{countCustomers}</h2>
              )}
            </div>
          </Box>
        </Grid>
        <Grid item xs={4} sx={{ color: "#fff" }}>
          <Box
            sx={{
              padding: 2,
              borderRadius: 5,
              boxShadow: 3,
              color: "#121E3D",
              background: "linear-gradient(to right bottom, #9be4f3, #9bc7f3)",
            }}
          >
            <div>
              <Book fontSize="large" />
            </div>

            <div className="mt-2">
              <span className="fs-6 fw-light">Numar total module</span>
            </div>

            <div className="mt-2">
              {isModulesCountLoading ? (
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              ) : (
                <h2>{countModules}</h2>
              )}
            </div>
          </Box>
        </Grid>
        <Grid sm={6} item xs={12} sx={{ color: "#fff", marginTop: 5 }}>
          <Box
            sx={{
              padding: 2,
              boxShadow: 3,
              borderRadius: 5,
              color: "#000",
              background: "#f0eff1",
            }}
          >
            <div className="mt-1">
              <span className="fs-5 fw-bold">Panou control - clienti</span>
            </div>

            <div className="mt-2 mb-2 me-auto">
              <Link to="/adaugare-client">
                <Button>Adauga client nou</Button>
              </Link>
            </div>

            {/* <div className="mt-2">
              <span className="fs-6 fw-normal">
                Cauta cantare pentru a o edita
              </span>
              <Autocomplete
                sm={12}
                sx={{ marginTop: 2, marginBottom: 2 }}
                multiple
                id="tags-standard"
                options={top100Films}
                getOptionLabel={(option) => option.title}
                defaultValue={[top100Films[13]]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Lista cantari"
                    placeholder="Cauta cantare"
                  />
                )}
              />
            </div> */}
          </Box>
        </Grid>
        <Grid sm={6} item xs={12} sx={{ color: "#fff", marginTop: 5 }}>
          <Box
            sm={12}
            sx={{
              padding: 2,
              borderRadius: 5,
              color: "#000",
              boxShadow: 3,
              background: "#f0eff1",
            }}
          >
            <div className="mt-1">
              <span className="fs-5 fw-bold">Panou control - sere</span>
            </div>

            <div className="mt-2 mb-2">
              <Button
                sx={{ textDecoration: "underline" }}
                onClick={() => {
                  setOpenCategoryModal(true);
                }}
              >
                Adauga sera noua - client existent
              </Button>
            </div>
            {/* 
            <div className="mt-2">
              <span className="fs-6 fw-normal pe-3 mb-2">
                Editeaza categorie
              </span>
              <Autocomplete
                sm={12}
                sx={{ marginTop: 2, marginBottom: 2 }}
                multiple
                id="tags-standard"
                options={top100Films}
                getOptionLabel={(option) => option.title}
                defaultValue={[top100Films[13]]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Lista cantari"
                    placeholder="Cauta cantare"
                  />
                )}
              />
            </div> */}
          </Box>
        </Grid>
        <CategoryForm
          show={openCategoryModal}
          handleClose={() => {
            setOpenCategoryModal(false);
          }}
        />
      </Grid>
    </Container>
  );
};

export default Dashboard;
