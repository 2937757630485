import { Box } from "@mui/material";
import TextInput from "../../../common/Input/TextInput";
import { distanceToNow } from "../../../helpers/date-helper";
import { useForm } from "react-hook-form";
import Button from "../../../common/Buttons/Button";

const CustomerForm = ({ customer, disabled, editCustomer }) => {
  const { register, handleSubmit } = useForm({
    defaultValues: {
      firstName: customer.firstName,
      lastName: customer.lastName,
      mail: customer.mail,
      phone: customer.phone,
    },
  });

  return (
    <form onSubmit={handleSubmit(editCustomer)}>
      <Box className="row">
        <Box className="d-flex">
          <TextInput
            register={register}
            name="firstName"
            disabled={disabled}
            label="Prenume"
            className="me-4 mb-4 d-flex flex-grow-1"
          />
          <TextInput
            register={register}
            name="lastName"
            disabled={disabled}
            label="Nume"
            className="d-flex flex-grow-1"
          />
        </Box>
        <Box className="d-flex">
          <TextInput
            register={register}
            name="mail"
            disabled={disabled}
            label="Utilizator"
            className="d-flex flex-grow-1 me-4 mb-4"
          />
          <TextInput
            register={register}
            name="phone"
            disabled={disabled}
            label="Numar telefon"
            className="d-flex flex-grow-1"
          />
        </Box>
        <Box className="d-flex">
          <TextInput
            withRegister={false}
            name="lastSeenOnline"
            readOnly={true}
            label="Ultima data on-line"
            className="d-flex flex-grow-1 me-4 mb-4"
            value={distanceToNow(customer.lastSeenOnline)}
          />
          <TextInput
            withRegister={false}
            name="createdAt"
            readOnly={true}
            label="Client creat in data de"
            className="d-flex flex-grow-1"
            value={customer.createdAt}
          />
        </Box>
        {disabled ? (
          <></>
        ) : (
          <Button className="gradient-button" title="Salveaza" type="submit" />
        )}
      </Box>
    </form>
  );
};

export default CustomerForm;
