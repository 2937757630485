import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Box,
} from "@mui/material";
import { MdOutlineExpandMore } from "react-icons/md";
import Button from "../../Buttons/Button";
import TextInput from "../../Input/TextInput";
import GradientText from "../../GradientText/GradientText";
import ModulesGreenHouseForm from "./ModulesGreenHouseForm";
import { apis } from "../../../services/api";

const GreenHouseForm = ({
  index,
  remove,
  idCustomer,
  register,
  control,
  item,
  formErrors,
  getValues,
  setValue,
}) => {
  const greenHouseFromDB =
    item.fromDB !== undefined
      ? item.fromDB === false
        ? ""
        : "(Sera existenta - Sera provine din baza de date)"
      : "";
  const idGreenHouse = getValues(`greenHouses[${index}].id`);

  const markGreenHouseToBeDeleted = () => {
    const user = JSON.parse(localStorage.getItem("user"));

    apis.deleteGreenHouse(user.userId, idGreenHouse).then((res) => {
      remove(index);
    });
  };

  return (
    <div className="row">
      <div className="col">
        <Accordion className="mb-4">
          <AccordionSummary
            expandIcon={<MdOutlineExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>{`Sera ${index + 1} ${greenHouseFromDB}`}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box className="mb-4">
              <TextInput
                register={register}
                fullWidth
                name={`greenHouses[${index}].name`}
                required="Va rog introduceti numele serei!"
                label="Nume sera"
                errors={formErrors}
              />
            </Box>
            <GradientText text={"Adaugare module pentru sera"} size="1.5em" />
            <ModulesGreenHouseForm
              idCustomer={idCustomer}
              control={control}
              greenHouseIndex={index}
              getValues={getValues}
              register={register}
              errors={formErrors}
              setValue={setValue}
              idGreenHouse={idGreenHouse}
              greenHouseFromDB={greenHouseFromDB}
            />
            {greenHouseFromDB && (
              <Button
                className="delete-button me-4 mt-4"
                type="button"
                size="small"
                onClick={markGreenHouseToBeDeleted}
                title={"Sterge sera din baza de date"}
              />
            )}
          </AccordionDetails>
        </Accordion>
      </div>
      <div className="col-1">
        {!greenHouseFromDB && (
          <Button
            className="delete-button me-4"
            type="button"
            size="small"
            onClick={() => {
              remove(index);
            }}
            title={"-"}
          />
        )}
      </div>
    </div>
  );
};

export default GreenHouseForm;
