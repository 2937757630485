import CenteredBox from "../../../common/CenteredBox/CenteredBox";
import Button from "../../../common/Buttons/Button";
import TextInput from "../../../common/Input/TextInput";

const LoginForm = ({
  register,
  onSubmit,
  handleSubmit,
  formErrors,
  requestErrors,
  submitting,
  userWasAutoLogOutMessage,
}) => (
  <form onSubmit={handleSubmit(onSubmit)}>
    <CenteredBox style={{ marginBottom: 5 }}>
      <TextInput
        register={register}
        fullWidth
        name="username"
        required="Va rog introduceti utilizator!"
        label="Utilizator"
        errors={formErrors}
      />
    </CenteredBox>

    <CenteredBox style={{ marginBottom: 5 }}>
      <TextInput
        register={register}
        fullWidth
        name="password"
        required="Va rog introduceti o parola!"
        label="Parola"
        type="password"
        errors={formErrors}
      />
    </CenteredBox>
    {userWasAutoLogOutMessage && (
      <CenteredBox>
        <p className="text-danger">{userWasAutoLogOutMessage}</p>
      </CenteredBox>
    )}
    {requestErrors && (
      <CenteredBox>
        <p className="text-danger">{requestErrors}</p>
      </CenteredBox>
    )}

    <CenteredBox style={{ marginBottom: 5 }}>
      <Button
        className="gradient-button d-flex flex-grow-1"
        submitting={submitting}
        type="submit"
        size="large"
        title={"Autentificare"}
      />
    </CenteredBox>

    <CenteredBox>
      <button className="btn btn-link">Recuperare parola</button>
    </CenteredBox>
  </form>
);

export default LoginForm;
