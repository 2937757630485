import { useForm } from "react-hook-form";
import Grid from "@mui/material/Grid/Grid";
import authImage from "../../assets/images/autentificare.jpeg";
import LoginForm from "./components/LoginForm";
import CenteredBox from "../../common/CenteredBox/CenteredBox";
import GlassBox from "../../common/GlassBox/GlassBox";
import { authActions } from "../../redux/actions/AuthActions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import React from "react";
import GradientText from "../../common/GradientText/GradientText";

const Login = () => {
  const authState = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const onSubmit = (data) => {
    dispatch(authActions.login(data));
  };

  React.useEffect(() => {
    if (authState.errors === null && authState.isAuthenticated)
      history.push("/");
  });

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        sx={{
          backgroundColor: "rgba(255, 255, 255, 0.4)",
          backgroundImage: `url(${authImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "100vh" }}
        >
          <GlassBox>
            <CenteredBox style={{ marginBottom: 8 }}>
              <GradientText text="RoboSer" size="2.5em" />
            </CenteredBox>
            <LoginForm
              requestErrors={authState.errors}
              formErrors={errors}
              register={register}
              handleSubmit={handleSubmit}
              onSubmit={onSubmit}
              submitting={authState.loading}
            />
          </GlassBox>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Login;
