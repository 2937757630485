import React, { useState, useCallback, useEffect } from "react";
import { Container, Box, Snackbar, Alert } from "@mui/material";
import GradientText from "../../common/GradientText/GradientText";
import { useParams } from "react-router-dom";
import { apis } from "../../services/api";
import CustomerForm from "./components/CustomerForm";
import Button from "../../common/Buttons/Button";
import { constants } from "../../constants/constants";
import CustomerGreenHouseForm from "../../common/CustomerGreenHouse/CustomerGreenHouseForm";

const CustomerDetail = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [openAlert, setOpenAlert] = useState(null);
  const [alertType, setAlertType] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);
  const [customerState, setCustomerState] = useState({
    isLoading: true,
    customer: null,
    error: null,
    disabled: true,
  });
  const [greenHousesState, setGreenHousesState] = useState({
    isLoading: false,
    data: { greenHouses: [] },
    error: null,
  });
  const user = JSON.parse(localStorage.getItem("user"));
  let { idCustomer } = useParams();

  const getCustomer = useCallback(() => {
    setCustomerState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    if (user !== null) {
      apis
        .getCustomer(idCustomer, user.userId)
        .then((customer) => {
          setCustomerState((prevState) => ({
            ...prevState,
            customer: customer,
          }));
        })
        .catch((err) => {
          console.log(err);
          setCustomerState((prevState) => ({
            ...prevState,
            error: err,
          }));
        })
        .finally(() => {
          setCustomerState((prevState) => ({
            ...prevState,
            isLoading: false,
          }));
        });
    }
  }, []);

  const getCustomerGreenHouses = useCallback(() => {
    setGreenHousesState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    if (user !== null) {
      apis
        .getCustomerGreenHouses(idCustomer, user.userId)
        .then((greenHouses) => {
          console.log("Greenhouses");
          console.log(greenHouses);
          setGreenHousesState((prevState) => ({
            ...prevState,
            data: greenHouses,
          }));
        })
        .catch((err) => {
          setGreenHousesState((prevState) => ({
            ...prevState,
            error: err,
          }));
        })
        .finally(() => {
          setGreenHousesState((prevState) => ({
            ...prevState,
            isLoading: false,
          }));
        });
    }
  }, []);

  const onEditCustomerHandler = (data) => {
    data.id = customerState.customer.id;

    apis
      .editCustomer(data, user.userId)
      .then((response) => {
        setAlertType(constants.ALERT_SUCCES);
        setAlertMessage("Clientul a fost modificat cu succes!");
        setOpenAlert(true);
      })
      .catch((err) => {
        setOpenAlert(true);
        setAlertType(constants.ALERT_ERROR);
        setAlertMessage(err);
        setIsLoading(false);
      })
      .finally(() => {
        setGreenHousesState((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  useEffect(() => {
    getCustomer();
    getCustomerGreenHouses();
  }, [getCustomer, getCustomerGreenHouses]);

  return (
    <Container>
      <Box className="d-flex justify-content-center">
        {customerState.isLoading ? (
          <div className="spinner-border mb-4" role="status">
            <span className="visually-hidden ">Se incarca...</span>
          </div>
        ) : (
          <Box className="mb-4">
            <GradientText text="Informatii client" />
            <Button
              className="mb-4"
              title="Editeaza client"
              onClick={() => {
                setCustomerState((prevState) => ({
                  ...prevState,
                  disabled: false,
                }));
              }}
            />
            <CustomerForm
              customer={customerState.customer}
              disabled={customerState.disabled}
              editCustomer={onEditCustomerHandler}
            />
          </Box>
        )}
      </Box>

      <Box id="sere">
        <GradientText text="Serele clientului" />
        {greenHousesState.data.greenHouses.length === 0 &&
        greenHousesState.isLoading ? (
          <Box className="d-flex justify-content-center">
            <div className="spinner-border mb-4" role="status">
              <span className="visually-hidden ">Se incarca...</span>
            </div>
          </Box>
        ) : (
          <CustomerGreenHouseForm
            idCustomer={idCustomer}
            onlyGreenHouses={true}
            greenHouses={greenHousesState.data.greenHouses}
          />
        )}
      </Box>
      <Snackbar
        open={openAlert}
        autoHideDuration={alertType === constants.ALERT_ERROR ? 40000 : 4000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert severity={alertType} sx={{ width: "100%" }}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default CustomerDetail;
