import { InputLabel, Select, FormControl, MenuItem } from "@mui/material";
import { Controller } from "react-hook-form";

const DropDown = ({
  name,
  control,
  className,
  data,
  multiple,
  propToDisplay,
  onChange,
  label,
  defaultValue,
  fullWidth,
}) => {
  const select = (field) => (
    <FormControl
      className={className}
      fullWidth={fullWidth === undefined ? true : fullWidth}
    >
      <InputLabel id={name}>{label}</InputLabel>
      {field ? (
        <Select
          {...field}
          labelId={name}
          label={label}
          multiple={multiple}
          defaultValue={defaultValue}
        >
          {data.map((item, index) => (
            <MenuItem key={index + name} value={item.id}>
              {item[propToDisplay]}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <Select
          onChange={onChange}
          labelId={name}
          label={label}
          multiple={multiple}
          defaultValue={defaultValue}
        >
          {data.map((item, index) => (
            <MenuItem key={index + name} value={item.id}>
              {item[propToDisplay]}
            </MenuItem>
          ))}
        </Select>
      )}
    </FormControl>
  );

  return control ? (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => select(field)}
    />
  ) : (
    select()
  );
};

export default DropDown;
