import { ControlPointSharp } from "@mui/icons-material";

export const transform = (obj) => {
  obj.greenHouses.forEach((grHouse, index) => {
    grHouse.modules.forEach((module, index) => {
      module.echipamenteModules.forEach((eq, index, eqArray) => {
        if (eq === 0) {
          eqArray[index] = {
            idEchipament: 1,
            info: `Fereastra 1`,
            indexEchipament: 0,
          };
        }

        if (eq === 1) {
          eqArray[index] = {
            idEchipament: 1,
            info: `Fereastra 2`,
            indexEchipament: 1,
          };
        }
        if (eq == 2) {
          eqArray[index] = {
            idEchipament: 3,
            info: "Temp",
            indexEchipament: 0,
          };
        }

        if (eq == 3) {
          eqArray[index] = {
            idEchipament: 4,
            info: "Umid aer",
            indexEchipament: 0,
          };
        }

        if (eq == 4) {
          eqArray[index] = {
            idEchipament: 7,
            info: "Umid sol",
            indexEchipament: 0,
          };
        }

        if (eq == 5) {
          eqArray[index] = {
            idEchipament: 6,
            info: "Viteza vant",
            indexEchipament: 0,
          };
        }

        if (eq == 6) {
          eqArray[index] = {
            idEchipament: 9,
            info: `Parcela 0`,
            indexEchipament: `${0}`,
          };
          module.echipamenteModules.push({
            idEchipament: 9,
            info: `Parcela 1`,
            indexEchipament: `${1}`,
          });
        }

        if (eq == 7) {
          for (let i = 0; i < 3; i++) {
            let indexEchipament = i % 2 == 1 ? 0 : 1;

            eqArray[index] = {
              idEchipament: 9,
              info: `Parcela ${index}`,
              indexEchipament: `${0}`,
            };

            module.echipamenteModules.push({
              idEchipament: 9,
              info: `Parcela ${index}`,
              indexEchipament: `${indexEchipament}`,
            });
          }
        }
      });
    });
  });
  const newArray = { ...obj };
  newArray.greenHouses = obj.greenHouses.filter((gr) => {
    if (gr.fromDB !== undefined) return gr.fromDB === false;
    else return gr;
  });
  return newArray;
};

export const transformModule = (module) => {
  module.echipamenteModules.forEach((eq, index, eqArray) => {
    if (eq === 0) {
      eqArray[index] = {
        idEchipament: 1,
        info: `Fereastra 1`,
        indexEchipament: 0,
      };
    }

    if (eq === 1) {
      eqArray[index] = {
        idEchipament: 1,
        info: `Fereastra 2`,
        indexEchipament: 1,
      };
    }
    if (eq == 2) {
      eqArray[index] = {
        idEchipament: 3,
        info: "Temp",
        indexEchipament: 0,
      };
    }

    if (eq == 3) {
      eqArray[index] = {
        idEchipament: 4,
        info: "Umid aer",
        indexEchipament: 0,
      };
    }

    if (eq == 4) {
      eqArray[index] = {
        idEchipament: 7,
        info: "Umid sol",
        indexEchipament: 0,
      };
    }

    if (eq == 5) {
      eqArray[index] = {
        idEchipament: 6,
        info: "Viteza vant",
        indexEchipament: 0,
      };
    }

    if (eq == 6) {
      eqArray[index] = {
        idEchipament: 9,
        info: `Parcela 0`,
        indexEchipament: `${0}`,
      };
      module.echipamenteModules.push({
        idEchipament: 9,
        info: `Parcela 1`,
        indexEchipament: `${1}`,
      });
    }

    if (eq == 7) {
      for (let i = 0; i < 3; i++) {
        let indexEchipament = i % 2 == 1 ? 0 : 1;

        eqArray[index] = {
          idEchipament: 9,
          info: `Parcela ${index}`,
          indexEchipament: `${0}`,
        };

        module.echipamenteModules.push({
          idEchipament: 9,
          info: `Parcela ${index}`,
          indexEchipament: `${indexEchipament}`,
        });
      }
    }
  });
  return module;
};

export const transformModuleWithExistingModuleAndAddNewEquipments = (
  module,
  echipamenteModulFromDB
) => {
  const echipamenteModuleToAdd = [
    ...module.echipamenteModules.filter(
      (element) => !echipamenteModulFromDB.includes(element)
    ),
    ...echipamenteModulFromDB.filter(
      (element) => !module.echipamenteModules.includes(element)
    ),
  ];

  module.echipamenteModules = echipamenteModuleToAdd;

  module.echipamenteModules.forEach((eq, index, eqArray) => {
    if (eq === 0) {
      eqArray[index] = {
        idEchipament: 1,
        info: `Fereastra 1`,
        indexEchipament: 0,
      };
    }

    if (eq === 1) {
      eqArray[index] = {
        idEchipament: 1,
        info: `Fereastra 2`,
        indexEchipament: 1,
      };
    }
    if (eq == 2) {
      eqArray[index] = {
        idEchipament: 3,
        info: "Temp",
        indexEchipament: 0,
      };
    }

    if (eq == 3) {
      eqArray[index] = {
        idEchipament: 4,
        info: "Umid aer",
        indexEchipament: 0,
      };
    }

    if (eq == 4) {
      eqArray[index] = {
        idEchipament: 7,
        info: "Umid sol",
        indexEchipament: 0,
      };
    }

    if (eq == 5) {
      eqArray[index] = {
        idEchipament: 6,
        info: "Viteza vant",
        indexEchipament: 0,
      };
    }

    if (eq == 6) {
      eqArray[index] = {
        idEchipament: 9,
        info: `Parcela 0`,
        indexEchipament: `${0}`,
      };
      module.echipamenteModules.push({
        idEchipament: 9,
        info: `Parcela 1`,
        indexEchipament: `${1}`,
      });
    }

    if (eq == 7) {
      for (let i = 0; i < 3; i++) {
        let indexEchipament = i % 2 == 1 ? 0 : 1;

        eqArray[index] = {
          idEchipament: 9,
          info: `Parcela ${index}`,
          indexEchipament: `${0}`,
        };

        module.echipamenteModules.push({
          idEchipament: 9,
          info: `Parcela ${index}`,
          indexEchipament: `${indexEchipament}`,
        });
      }
    }
  });
  return module;
};
