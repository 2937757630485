export default {
  customer: {
    firstName: "",
    lastName: "",
    mail: "",
    password: "test",
    phone: "",
  },
  greenHouses: [
    {
      name: "Sera 1",
      modules: [
        {
          name: "Modul - Sera 1 - Motor; temp etc. - ",
          serialNumber: "",
          blocked: false,
          exterior: false,
          echipamenteModules: [0, 1, 2, 3],
        },
        {
          name: "Modul - Sera 1 - Viteza vant",
          serialNumber: "",
          blocked: false,
          exterior: true,
          echipamenteModules: [5],
        },
        {
          name: "Modul - Sera 1 - Irigare",
          serialNumber: "",
          blocked: false,
          exterior: true,
          echipamenteModules: [6],
        },
      ],
    },
    {
      name: "Sera 2",
      modules: [
        {
          name: "Modul - Sera 1 - Motor; temp etc. - ",
          serialNumber: "",
          blocked: false,
          exterior: false,
          echipamenteModules: [0, 1, 2, 3],
        },
        {
          name: "Modul - Sera 1 - Viteza vant",
          serialNumber: "",
          blocked: false,
          exterior: true,
          echipamenteModules: [5],
        },
        {
          name: "Modul - Sera 1 - Irigare",
          serialNumber: "",
          blocked: false,
          exterior: true,
          echipamenteModules: [6],
        },
      ],
    },
  ],
};
