import { Modal } from "@mui/material";
import GlassBox from "../GlassBox/GlassBox";
import "../../sass/modal.scss";
import Backdrop from "@mui/material/Backdrop";
import GradientText from "../GradientText/GradientText";

const ModalPage = ({ show, handleClose, title, children }) => {
  return (
    <Modal
      open={show}
      onClose={handleClose}
      aria-labelledby="New/Edit Category"
      aria-describedby="Modal for editign or adding a new category!"
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 900,
        sx: {
          backgroundColor: "rgba(0, 0, 0, 0.2)",
        },
      }}
    >
      <div className="modal-container">
        <GlassBox>
          <GradientText text={title} size="2em" />
          {children}
        </GlassBox>
      </div>
    </Modal>
  );
};

export default ModalPage;
