export const initialState = {
  authInitialState: {
    user: null,
    isAuthenticated: false,
    tokenExpirationDate: null,
    loading: false,
    errors: null,
    userWasAutoLogOutMessage: null,
  },
};
