import React, { useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import { apis } from "../../services/api";
import { constants } from "../../constants/constants";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Snackbar, Alert, Tab, Box } from "@mui/material";
import Button from "../Buttons/Button";
import GradientText from "../GradientText/GradientText";
import "./greenHouse.scss";
import GreenHousesForm from "./components/GreenHousesForm";
import { transform } from "../../helpers/transform";
import CustomerForm from "./components/CustomerForm";
import defaultCustomer from "./components/defaultCustomer";

const CustomerGreenHouseForm = ({
  greenHouses,
  onlyGreenHouses = false,
  idCustomer,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [openAlert, setOpenAlert] = useState(null);
  const [alertType, setAlertType] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);
  const [error, setError] = useState(null);
  const [tab, setTab] = useState(!onlyGreenHouses ? "client" : "sere");

  const handleTabChange = (event, newTab) => {
    setTab(newTab);
  };

  const {
    register,
    handleSubmit,
    reset,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onBlur",

    defaultValues:
      greenHouses !== undefined
        ? {
            greenHouses: greenHouses,
          }
        : defaultCustomer,
  });

  const alertOnClose = () => {
    if (errors == null) reset();

    setOpenAlert(false);
  };
  const user = JSON.parse(localStorage.getItem("user"));
  const isNewCustomer = greenHouses === undefined ? true : false;

  const onSubmit = (data) => {
    const request = transform(data);

    if (!isNewCustomer) {
      request.idCustomer = idCustomer;
      editCustomerHandler(request);
    } else {
      addCustomerHandler(request);
    }
  };

  const addCustomerHandler = (data) => {
    setIsLoading(true);
    if (user !== null) {
      apis
        .addNewCustomerAndGreenHouses(data, user.userId)
        .then((res) => {
          setIsLoading(false);
          setAlertType(constants.ALERT_SUCCES);
          setAlertMessage("Clientul si serele au fost adaugate cu succes!");
          setOpenAlert(true);
        })
        .catch((errors) => {
          setError(errors);
          setOpenAlert(true);
          setAlertType(constants.ALERT_ERROR);
          setAlertMessage(errors);
          setIsLoading(false);
        });
    }
  };

  const editCustomerHandler = (data) => {
    setIsLoading(true);
    if (user !== null) {
      apis
        .addOrEditGreenHouse(data, user.userId)
        .then((res) => {
          setIsLoading(false);
          setAlertType(constants.ALERT_SUCCES);
          setAlertMessage("Serele au fost adaugate/editate cu succes!");
          setOpenAlert(true);
        })
        .catch((errors) => {
          setOpenAlert(true);
          setAlertType(constants.ALERT_ERROR);
          setAlertMessage(errors);
          setIsLoading(false);
        });
    }
  };

  return (
    <>
      {!onlyGreenHouses && (
        <GradientText text={"Adaugare client si sere"} size="2em" />
      )}

      <form className="greenHouse-container " onSubmit={handleSubmit(onSubmit)}>
        <TabContext value={tab}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleTabChange}
              aria-label="Formular de adaugare client nou"
            >
              {!onlyGreenHouses && <Tab label="Client" value="client" />}
              <Tab label="Sere" value="sere" />
            </TabList>
          </Box>

          {!onlyGreenHouses && (
            <TabPanel value="client">
              <CustomerForm register={register} formErrors={errors} />
            </TabPanel>
          )}

          <TabPanel value="sere">
            <GreenHousesForm
              onlyGreenHouses={onlyGreenHouses}
              idCustomer={idCustomer}
              control={control}
              register={register}
              getValues={getValues}
              setValue={setValue}
            />
          </TabPanel>
        </TabContext>

        <Box className="text-end mt-4">
          <Button
            className="gradient-button me-4"
            submitting={isLoading}
            type="submit"
            size="large"
            title={
              !onlyGreenHouses
                ? "Adauga client"
                : "Adauga noile sere in baza de date"
            }
          />
        </Box>
      </form>
      <Snackbar
        open={openAlert}
        autoHideDuration={alertType === constants.ALERT_ERROR ? 40000 : 4000}
        onClose={alertOnClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={alertOnClose}
          severity={alertType}
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default CustomerGreenHouseForm;
