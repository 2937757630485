import { Box } from "@mui/material";
const GlassBox = ({ children, style, className }) => (
  <Box
    xs={4}
    sm={4}
    md={12}
    sx={{
      width: 550,
      // height: 590,
      backgroundColor: "rgba(255, 255, 255, 0.4)",
      borderRadius: 10,
      padding: 10,
      backgroundImage:
        "linear-gradient(to bottom right, rgba(255,255,775,0.2), rgba(255,255,255,0))",
      backdropFilter: "blur(10px)",
      boxShadow: "10px 10px 10px rgba(30,30,30,0.5)",
      ...style,
    }}
    className={className}
  >
    {children}
  </Box>
);

export default GlassBox;
