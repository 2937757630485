import { TextField } from "@mui/material";

const TextInput = ({
  errors,
  register,
  name,
  required,
  fullWidth,
  label,
  type,
  className,
  onChange,
  value,
  disabled,
  readOnly,
  withRegister = true,
  maxLength,
}) =>
  withRegister ? (
    <TextField
      InputProps={{
        readOnly: readOnly,
      }}
      disabled={disabled}
      className={className}
      error={errors !== undefined ? (errors[name] ? true : false) : false}
      {...register(name, { required, maxLength: maxLength })}
      fullWidth={fullWidth}
      label={
        errors !== undefined
          ? errors[name]
            ? errors[name].message
            : label
          : label
      }
      variant="outlined"
      type={type}
    />
  ) : (
    <TextField
      disabled={disabled}
      className={className}
      onChange={onChange}
      name={name}
      fullWidth={fullWidth}
      label={label}
      InputProps={{
        readOnly: readOnly,
      }}
      value={value}
      variant="outlined"
      type={type}
    />
  );

export default TextInput;
