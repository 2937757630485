import { authorization } from "./authorization-header";

const responseError = (error) => {
  let errMessage;
  if (error.status !== undefined) {
    if (error.status === 401) {
      localStorage.removeItem("user");
      errMessage = "Nu sunteti autorizat!";
      window.location.href = "./autentificare";
    } else if (error.status === 500) {
      errMessage = "Server Error";
    }
  } else {
    const errString = error.toString();
    if (errString.includes("TypeError: Failed to fetch"))
      errMessage = "Server-ul nu este disponibil!";
    else errMessage = error;
  }

  return Promise.reject(errMessage);
};

// Poate pot face ceva cu asta sa generalizam
// const handleErrStatuses = (status, error) => {
//   let errMessage;
//   if (status !== null) {
//     if (status === 401) localStorage.removeItem("user");
//     if (status === 500) errMessage = "Server Error";
//   } else {
//     const errString = error.toString();
//     if (errString.includes("TypeError: Failed to fetch"))
//       errMessage = "Server-ul nu este disponibil!";
//     else errMessage = error;
//   }

//   return Promise.reject(errMessage);
// };

const handleResponse = async (response) => {
  if (response.status === 401) localStorage.removeItem("user");
  if (response.status === 500) return Promise.reject("Server error!");

  // Take data from response json
  // If we have errors we reject and return the error message
  // Otherwise we take data and return it
  let data;
  const result = await response.json();

  if (result.errors !== null) return Promise.reject(result.errors);
  if (result.data !== null) data = result.data;

  if (response.status === 200 || response.status === 201) return data;
};

const fetchInstance = async (url, data, method) => {
  const tokenAuth = authorization();

  return fetch(url, {
    method: method,
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: tokenAuth,
      "Access-Control-Allow-Origin": "*",
    },
    body: JSON.stringify(data),
  })
    .then((response) => handleResponse(response))
    .then((data) => {
      return data;
    })
    .catch((error) => responseError(error));
};

export const http = {
  post: (url, data) => fetchInstance(url, data, "POST"),
  get: async (url, data) => fetchInstance(url, data, "GET"),
  put: async (url, data) => fetchInstance(url, data, "PUT"),
  delete: async (url, data) => fetchInstance(url, data, "DELETE"),
};
