import { useState } from "react";
import { useForm } from "react-hook-form";
import { apis } from "../../services/api";
import TextInput from "../Input/TextInput";
import ModalPage from "../Modal/ModalPage";
import CenteredBox from "../CenteredBox/CenteredBox";
import { constants } from "../../constants/constants";
import { Snackbar, Alert } from "@mui/material";
import Button from "../Buttons/Button";

const CategoryForm = ({ show, handleClose, category }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [openAlert, setOpenAlert] = useState(null);
  const [alertType, setAlertType] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      name: category?.name,
    },
  });

  const alertOnClose = () => {
    reset();
    setOpenAlert(false);
  };

  const isNewCategory = category === undefined ? true : false;

  const onSubmit = (data) => {
    // If the category exists, so we edit the category
    if (!isNewCategory) {
      data.id = category.id;
      editCategoryHandler(data);
    } else {
      addCategoryHandler(data);
    }
  };

  const addCategoryHandler = (data) => {
    setIsLoading(true);
    apis
      .addCategory(data)
      .then((res) => {
        setIsLoading(false);
        setAlertType(constants.ALERT_SUCCES);
        setAlertMessage("Categoria a fost adaugata cu succes!");
        setOpenAlert(true);
      })
      .catch((errors) => {
        setOpenAlert(true);
        setAlertType(constants.ALERT_ERROR);
        setAlertMessage(errors);
        setIsLoading(false);
      });
  };

  const editCategoryHandler = (data) => {
    setIsLoading(true);
    apis
      .editCategory(data)
      .then((res) => {
        setIsLoading(false);
        setAlertType(constants.ALERT_SUCCES);
        setAlertMessage("Categoria a fost editata cu succes!");
        setOpenAlert(true);
      })
      .catch((errors) => {
        setOpenAlert(true);
        setAlertType(constants.ALERT_ERROR);
        setAlertMessage(errors);
        setIsLoading(false);
      });
  };

  return (
    <>
      <ModalPage
        show={show}
        handleClose={handleClose}
        title={isNewCategory ? "Adauga categorie noua!" : "Editeaza categoria"}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <CenteredBox style={{ marginBottom: 5 }}>
            <TextInput
              register={register}
              fullWidth
              name="name"
              required="Va rog introduceti numele categoriei!"
              label="Nume categorie"
              errors={errors}
            />
          </CenteredBox>

          <CenteredBox style={{ marginBottom: 3 }} className="d-flex">
            <Button
              className="gradient-button me-4 flex-grow-1"
              submitting={isLoading}
              type="submit"
              size="large"
              title={isNewCategory ? "Adauga categorie" : "Editeaza categoria"}
            />
            <Button
              className="secondary-button flex-grow-1"
              onClick={handleClose}
              size="large"
              title="Inchide"
            />
          </CenteredBox>
        </form>
      </ModalPage>
      <Snackbar
        open={openAlert}
        autoHideDuration={4000}
        onClose={alertOnClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={alertOnClose}
          severity={alertType}
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default CategoryForm;
