import {
  Switch,
  Route,
  Redirect,
  BrowserRouter as Router,
} from "react-router-dom";
import Dashboard from "./pages/Dashboard/Dashboard";
import Login from "./pages/Login/Login";
import Layout from "./common/Layout/Layout";
import { useSelector } from "react-redux";
import CustomerGreenHouseForm from "./common/CustomerGreenHouse/CustomerGreenHouseForm";
import Customers from "./pages/Customers/Customers";
import CustomerDetail from "./pages/Customer/CustomerDetail";

function App() {
  const authState = useSelector((state) => state.auth);

  return (
    <Router>
      <Layout>
        <Switch>
          <Route exact path="/">
            {authState.isAuthenticated ? (
              <Dashboard />
            ) : (
              <Redirect to="/autentificare" />
            )}
          </Route>

          <Route exact path="/clienti">
            {authState.isAuthenticated ? (
              <Customers />
            ) : (
              <Redirect to="/autentificare" />
            )}
          </Route>

          <Route exact path="/client/:idCustomer">
            {authState.isAuthenticated ? (
              <CustomerDetail />
            ) : (
              <Redirect to="/autentificare" />
            )}
          </Route>

          <Route exact path="/adaugare-client">
            {authState.isAuthenticated ? (
              <CustomerGreenHouseForm />
            ) : (
              <Redirect to="/autentificare" />
            )}
          </Route>
          <Route exact path="/sera/:nume">
            {authState.isAuthenticated ? (
              <Dashboard />
            ) : (
              <Redirect to="/autentificare" />
            )}
          </Route>
          <Route exact path="/autentificare">
            <Login />
          </Route>
          <Redirect from="*" to="/" />
        </Switch>
      </Layout>
    </Router>
  );
}

export default App;
