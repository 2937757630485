import { formatDistanceToNow } from "date-fns";
import { format } from "date-fns";
export const distanceToNow = (date) => {
  if (date === undefined || date === null || date == "0000-00-00")
    return "Clientul inca nu a intrat in cont";

  return formatDistanceToNow(new Date(date), { addSuffix: true });
};

export const formatDate = (date) => {
  if (date === undefined || date === null) return "";
  const timeZone = "UTC";
  //const theDate =

  return format(new Date(date), "yyyy/mm/dd");
};
