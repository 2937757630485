import { http } from "../helpers/http";
// const BASE_URL = "https://dev.roboser.ro";
const BASE_URL = "https://applicationinterface.roboser.ro";
//const BASE_URL = "http://localhost:3001";

export const apis = {
  login: (user) => http.post(`${BASE_URL}/api/admin/login`, user),
  addNewCustomerAndGreenHouses: (setUpNewCustomer, idUser) =>
    http.post(`${BASE_URL}/api/greenhouse/add/${idUser}`, setUpNewCustomer),
  getCustomers: (params, idUser) =>
    http.get(
      `${BASE_URL}/api/customers/${idUser}?` +
        new URLSearchParams({
          page: params.page,
          size: params.size,
          name: params.name,
        })
    ),
  getCustomer: (idCustomer, idUser) =>
    http.get(`${BASE_URL}/api/customer/${idCustomer}/${idUser}?`),
  editCustomer: (customer, idUser) =>
    http.put(`${BASE_URL}/api/customers/edit/${idUser}?`, customer),
  countGreenHouse: (idUser) =>
    http.get(`${BASE_URL}/api/greenhouse/count/${idUser}`),
  countCustomers: (idUser) =>
    http.get(`${BASE_URL}/api/customers/count/${idUser}`),
  countModules: (idUser) => http.get(`${BASE_URL}/api/modules/count/${idUser}`),
  getCustomerGreenHouses: (customerId, idUser) =>
    http.get(`${BASE_URL}/api/admin/greenhouses/${customerId}/${idUser}`),
  addOrEditGreenHouse: (data, idUser) =>
    http.post(`${BASE_URL}/api/admin/greenhouse/addOrEdit/${idUser}`, data),
  addModuleToGreenHouse: (data, idUser) =>
    http.post(`${BASE_URL}/api/admin/greenhouse/addModule/${idUser}`, data),
  editModuleToDB: (data, idUser) =>
    http.put(`${BASE_URL}/api/admin/greenhouse/editModule/${idUser}`, data),
  deleteModule: (idUser, idModul) =>
    http.delete(
      `${BASE_URL}/api/admin/greenhouse/deleteModule/${idUser}/${idModul}`
    ),
  deleteGreenHouse: (idUser, idGreenHouse) =>
    http.delete(
      `${BASE_URL}/api/admin/greenhouse/deleteGreenHouse/${idUser}/${idGreenHouse}`
    ),
};
