import { useEffect, useState } from "react";
import { useFieldArray } from "react-hook-form";
import Button from "../../Buttons/Button";
import { Box } from "@mui/system";
import TextInput from "../../Input/TextInput";
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Switch,
  Snackbar,
  Alert,
} from "@mui/material";

import { MdOutlineExpandMore } from "react-icons/md";
import DropDown from "../../Select/DropDown";
import { apis } from "../../../services/api";
import {
  transformModuleWithExistingModuleAndAddNewEquipments,
  transformModule,
} from "../../../helpers/transform";
import { constants } from "../../../constants/constants";

const equipments = [
  {
    id: 0,
    name: "Motor 1",
  },
  {
    id: 1,
    name: "Motor 2",
  },
  {
    id: 2,
    name: "Temperatura",
  },
  {
    id: 3,
    name: "Umiditate aer",
  },
  {
    id: 4,
    name: "Umiditate sol",
  },
  {
    id: 5,
    name: "Viteza vant",
  },
  {
    id: 6,
    name: "Irigare(2 buc)",
  },
  {
    id: 7,
    name: "Irigare(4 buc)",
  },
];

const ModulesGreenHouseForm = ({
  control,
  greenHouseIndex,
  idGreenHouse,
  register,
  greenHouseFromDB,
  errors,
  getValues,
  idCustomer,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [openAlert, setOpenAlert] = useState(null);
  const [alertType, setAlertType] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);
  const [moduleFromDB, setModuleFromDB] = useState(null);

  const { fields, append, remove } = useFieldArray({
    control,
    name: `greenHouses[${greenHouseIndex}].modules`,
  });

  const user = JSON.parse(localStorage.getItem("user"));

  const markModuleToBeDeleted = (index) => {
    const idModul = getValues(
      `greenHouses[${greenHouseIndex}].modules[${index}].id`
    );
    apis.deleteModule(user.userId, idModul).then((res) => {
      remove(index);
    });
  };

  useEffect(() => {
    const greenHousesModules = getValues(
      `greenHouses[${greenHouseIndex}].modules`
    );
    setModuleFromDB(greenHousesModules);
  }, []);

  console.log(moduleFromDB);

  const alertOnClose = () => {
    window.location.reload(true);
  };

  const addModuleToDB = (data) => {
    apis
      .addModuleToGreenHouse(data, user.userId)
      .then((res) => {
        setIsLoading(false);
        setAlertType(constants.ALERT_SUCCES);
        setAlertMessage("Modulul a fost adaugat cu succes in baza de date!");
        setOpenAlert(true);
      })
      .catch((err) => {
        setOpenAlert(true);
        setAlertType(constants.ALERT_ERROR);
        setAlertMessage(errors);
        setIsLoading(false);
      });
  };

  const editModuleToDB = (data) => {
    apis
      .editModuleToDB(data, user.userId)
      .then((res) => {
        setIsLoading(false);
        setAlertType(constants.ALERT_SUCCES);
        setAlertMessage("Modulul a fost adaugat cu succes in baza de date!");
        setOpenAlert(true);
      })
      .catch((err) => {
        setOpenAlert(true);
        setAlertType(constants.ALERT_ERROR);
        setAlertMessage(errors);
        setIsLoading(false);
      });
  };

  return (
    <Box className="border rounded-3 p-4">
      {fields.map((field, index) => {
        return (
          <Accordion key={field.id} className="mb-4">
            <AccordionSummary
              expandIcon={<MdOutlineExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>{`Modul ${index + 1}`}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box className="mb-4 row">
                <Box className="col">
                  <TextInput
                    register={register}
                    fullWidth
                    name={`greenHouses[${greenHouseIndex}].modules[${index}].name`}
                    label="Nume modul"
                    errors={errors}
                  />
                </Box>
                <Box className="col">
                  <TextInput
                    register={register}
                    fullWidth
                    name={`greenHouses[${greenHouseIndex}].modules[${index}].serialNumber`}
                    label="Topic modul"
                    errors={errors}
                  />
                </Box>
              </Box>
              <Box className="mb-4 row">
                <Box className="col">
                  <DropDown
                    control={control}
                    name={`greenHouses[${greenHouseIndex}].modules[${index}].echipamenteModules`}
                    data={equipments}
                    label="Echipamente modul:"
                    multiple={true}
                    propToDisplay="name"
                  />
                </Box>

                <Box className="col-2">
                  <span>Modul exterior:</span>
                  <Switch
                    {...register(
                      `greenHouses[${greenHouseIndex}].modules[${index}].exterior`
                    )}
                    color="primary"
                  />
                </Box>
              </Box>

              <Button
                className="delete-button me-4 mb-4"
                type="button"
                size="small"
                onClick={() => {
                  if (
                    !greenHouseFromDB && field.newModule
                      ? remove(index)
                      : markModuleToBeDeleted(index, field.id)
                  );
                }}
                title={
                  field.newModule
                    ? "Sterge Modul"
                    : "Sterge modul din baza de date"
                }
              />
              {greenHouseFromDB && field.newModule && (
                <Button
                  className="gradient-button me-4 mb-4"
                  type="button"
                  size="small"
                  onClick={() => {
                    const moduleData = getValues(
                      `greenHouses[${greenHouseIndex}].modules[${index}]`
                    );
                    const data = {};
                    data.module = transformModule(moduleData);
                    data.idGreenHouse = idGreenHouse;
                    data.idCustomer = idCustomer;

                    addModuleToDB(data);
                  }}
                  title="Adauga modul in baza de date"
                />
              )}
              {greenHouseFromDB && !field.newModule && (
                <Button
                  className="gradient-button me-4 mb-4"
                  type="button"
                  size="small"
                  onClick={() => {
                    const moduleData = getValues(
                      `greenHouses[${greenHouseIndex}].modules[${index}]`
                    );

                    const moduleEchipamente =
                      moduleFromDB[index].echipamenteModules;

                    const data = {};

                    data.module =
                      transformModuleWithExistingModuleAndAddNewEquipments(
                        moduleData,
                        moduleEchipamente
                      );

                    data.idGreenHouse = idGreenHouse;
                    data.idCustomer = idCustomer;

                    editModuleToDB(data);
                  }}
                  title="Salveaza modificarile in baza de date"
                />
              )}
            </AccordionDetails>
          </Accordion>
        );
      })}
      <Button
        className="secondary-button me-4 mb-4"
        type="button"
        size="small"
        onClick={() => {
          append({
            name: `Modul - Sera ${greenHouseIndex + 1} - ${getValues(
              "customer.firstName"
            )} ${getValues("customer.lastName")}`,
            serialNumber: "",
            blocked: false,
            exterior: false,
            echipamenteModules: [0, 1, 2, 3],
            newModule: true,
          });
        }}
        title={"Adauga Modul"}
      />
      <Snackbar
        open={openAlert}
        autoHideDuration={alertType === constants.ALERT_ERROR ? 40000 : 4000}
        onClose={alertOnClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={alertOnClose}
          severity={alertType}
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ModulesGreenHouseForm;
